import * as Yup from "yup";

export interface FeatureBannerResponseModel {
  message: string;
  url: string;
  date: string;
};

export interface FeatureBannerFormModel {
  message?: string;
  url?: string;
  recipientType?: "CUSTOMER" | "EMPLOYEE";
};

export const FeatureBannerSchema: Yup.ObjectSchema<FeatureBannerFormModel> = Yup.object().shape({
  message: Yup.string().max(150, "The value must be no more than 150 characters"),
  url: Yup.string().max(255, "The value must be no more than 255 characters")
}).defined();

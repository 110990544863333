import api, { ApiResponse } from "./../api";
import {
  FeatureBannerFormModel,
  FeatureBannerResponseModel
} from "./models";

class FeatureBannerService {
  private static instance: FeatureBannerService;
  private constructor() {}
  static getInstance(): FeatureBannerService {
    if (!FeatureBannerService.instance) {
      FeatureBannerService.instance = new FeatureBannerService();
    }
    return FeatureBannerService.instance;
  }

  public getFeatureBanners = async (): Promise<ApiResponse<any>> => {
    return await api.get("/notifications/banner");
  };

  public getUserFeatureBanner = async (): Promise<ApiResponse<any>> => {
    return await api.get("/notifications/banner/user");
  };

  public saveFeatureBanner = async (
    request: FeatureBannerFormModel
  ): Promise<ApiResponse<FeatureBannerResponseModel>> => {
    return await api.post("/notifications/banner", request);
  };
}

export default FeatureBannerService;

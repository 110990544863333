import React, { useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "../../../ui-components/tabs/tabs.scss";
import ContentContainer from "../../../templates/content-container/contentContainer";
import { PathParams, ShipmentDetailsPath } from "./route";
import ShipmentDetailsState from "../../../slices/shipment-details/ShipmentDetailsState";
import {
  clearDockLog,
  clearStatusHistory,
  shipmentDetailsSelector,  
  getShipmentDetails,
  resetShipmentDetails
} from "../../../slices/shipment-details/shipmentDetailsSlice";
import InvoiceDetailsInfo from "../../invoices/invoice-details/info/invoiceDetailsInfo";
import InvoiceDetailsDocuments from "../../invoices/invoice-details/documents/invoiceDetailsDocuments";
import InvoiceDetailsRecords from "../../invoices/invoice-details/records/invoiceDetailsRecords";
import InvoiceDetailsTable from "../../invoices/invoice-details/table/invoiceDetailsTable";
import FitToWindowContainer from "../../../templates/fit-to-window-container/fitToWindowContainer";
import ShipmentDetailsHeader from "./header/shipmentDetailsHeader";
import InfoBlock from "../../../templates/info-block/infoBlock";
import LabeledValue from "../../../ui-components/molecules/labeled-value/labeledValue";
import InfoGrid from "../../../templates/info-grid/infoGrid";
import {
  ContentItemTitle,
  ContentItem
} from "../../../templates/content-item/contentItem";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import SteppedProgress from "../../../ui-components/stepped-progress/steppedProgress";
import BlockPlaceholder from "../../../ui-components/block-placeholder/blockPlaceholder";
import InvoicePayment from "../../invoices/invoice-payment/invoicePayment";
import ShipmentClaimsNumbers from "./shipmentClaimsNumbers";
import ShipmentComment from "./shipmentComment";
import ShipmentDockLog from "./shipmentDockLog";
import ShipmentStatusHistory from "./shipmentStatusHistory";
import ShipmentClaimsDocuments from "./shipmentClaimsDocuments";
import RoutingGuide from "../../../app/data/tracking/RoutingGuide";
import { userSelector } from "../../../slices/user/userSlice";
import UserState from "../../../slices/user/UserState";
import { UserUtils } from "../../../app/data/user/userUtils";
import "./shipmentDetails.scss";
import ShipmentExceptions from "./shipmentExceptions";
import { CreateException } from "../../exceptions/createException";
import { getExceptions, resetExceptionState } from "../../../slices/exceptions/exceptionsSlice";

const ShipmentDetails: React.FC<{}> = (props) => {
  const userState: UserState = useSelector(userSelector);
  const shipmentDetailsState: ShipmentDetailsState = useSelector(shipmentDetailsSelector);
  const dispatch = useDispatch();
  const params = useParams() as PathParams;
  const invoiceNumber = useMemo(() => {
    return parseInt(params[ShipmentDetailsPath.invoiceNumber], 10);
  }, [params]);

  const showDay = (value: string) => {
    return value || "_";
  };

  const getRoutingGuideString = (guide: RoutingGuide) => {
    return (
      showDay(guide.monday) +
      " " +
      showDay(guide.tuesday) +
      " " +
      showDay(guide.wednesday) +
      " " +
      showDay(guide.thursday) +
      " " +
      showDay(guide.friday) +
      " " +
      showDay(guide.saturday) +
      " " +
      showDay(guide.sunday)
    );
  };

  useEffect(() => {
    if (invoiceNumber || shipmentDetailsState.needToReload) {
      dispatch(getShipmentDetails(invoiceNumber));
      UserUtils.isEmployee(userState.profile) && dispatch(getExceptions(invoiceNumber));
      return function cleanup() {
        dispatch(resetShipmentDetails());
        dispatch(clearDockLog());
        dispatch(clearStatusHistory());
        dispatch(resetExceptionState());
      };
    }
  }, [dispatch, invoiceNumber, shipmentDetailsState.needToReload, userState.profile]);

  useEffect(() => {
    if (UserUtils.isXGSUser(userState.profile) || UserUtils.isXGSAdministrator(userState.profile)) return;
    mixpanel.track("Viewed a probill", { "Probill": invoiceNumber });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ContentContainer titleComponent={<ShipmentDetailsHeader />}>
        <FitToWindowContainer>
          {shipmentDetailsState.loadingFailed && (
            <XGSErrorMessage>{shipmentDetailsState.error}</XGSErrorMessage>
          )}
          {shipmentDetailsState.loaded && (
            <div className="xgs-shipment-details">
              {shipmentDetailsState?.shipment?.progress && (
                <div style={{
                  maxWidth: 896,
                  marginBottom: 36
                }}>
                  <SteppedProgress
                    shipmentDetails={shipmentDetailsState?.shipment}
                  />
                </div>
              )}
              {shipmentDetailsState.shipment && (
                <>
                  {shipmentDetailsState.shipment.claims && (
                    <ShipmentClaimsNumbers claims={shipmentDetailsState.shipment.claims} />
                  )}
                  {shipmentDetailsState.shipment?.status?.title !== "BOOKED" && (
                    <InfoGrid className="xgs-shipment-details__grid">
                      <InfoBlock title="ETA">
                        {(shipmentDetailsState?.shipment?.eta?.originalEta || shipmentDetailsState?.shipment?.eta?.expectedEta) && (
                          <>
                            <LabeledValue
                              label="Original ETA:"
                              value={shipmentDetailsState?.shipment?.eta?.originalEta?.toUiDateFormat()}
                              labelFixWidth
                            />
                            <LabeledValue
                              label="Expected ETA:"
                              value={shipmentDetailsState?.shipment?.eta?.expectedEta?.toUiDateFormat()}
                              labelFixWidth
                            />
                            {shipmentDetailsState?.shipment?.eta?.notes && shipmentDetailsState?.shipment?.eta?.notes.length > 0 && (
                              <div className="xgs-form__field__notes">
                                <strong>Note{shipmentDetailsState.shipment.eta.notes.length > 1 ? "s" : ""}:</strong>&nbsp;
                                {shipmentDetailsState.shipment.eta.notes.length === 1 && (
                                  <>
                                    {shipmentDetailsState.shipment.eta.notes[0]}
                                  </>
                                )}
                                {shipmentDetailsState.shipment.eta.notes.length > 1 && (
                                  <ul>
                                    {shipmentDetailsState.shipment.eta.notes.map(note => (
                                      <li>
                                        {note}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                            )}
                          </>
                        )}
                        {shipmentDetailsState?.shipment?.routingGuide && (
                          <LabeledValue
                            label="Routing guide"
                            value={getRoutingGuideString(
                                shipmentDetailsState.shipment.routingGuide
                            )}
                            labelFixWidth
                          />
                        )}
                        {!shipmentDetailsState?.shipment?.eta?.originalEta &&
                          !shipmentDetailsState?.shipment?.eta?.expectedEta &&
                          !shipmentDetailsState?.shipment?.routingGuide &&
                          !shipmentDetailsState?.shipment?.eta?.notes && (
                          <BlockPlaceholder />
                        )}
                      </InfoBlock>
                      <InfoBlock title="POD" className="xgs-shipment-details__grid__double-column">
                        <div className={`
                          xgs-shipment-details__grid__double-column__grid
                          ${!shipmentDetailsState?.shipment?.pod ? " xgs-shipment-details__grid__double-column__grid--empty" : ""}
                        `}>
                          {shipmentDetailsState?.shipment?.pod && (
                            <>
                              <div style={{ marginRight: 24 }}>
                                <LabeledValue
                                  label="POD:"
                                  value={shipmentDetailsState.shipment.pod.date ? `${shipmentDetailsState.shipment.pod.date.toUiDateFormat()} ${shipmentDetailsState.shipment.pod.time ? shipmentDetailsState.shipment.pod.time : ''}` : ''}
                                  labelFixWidth
                                />
                                <LabeledValue
                                  label="By:"
                                  value={shipmentDetailsState.shipment.pod.signature || ''}
                                  labelFixWidth
                                />
                              </div>
                              {shipmentDetailsState.shipment.fullAccess && (
                                <div>
                                  <LabeledValue
                                    label="POD Entered:"
                                    value={shipmentDetailsState.shipment.pod.enteredDate ? `${shipmentDetailsState.shipment.pod.enteredDate.toUiDateFormat()} ${shipmentDetailsState.shipment.pod.enteredTime ? shipmentDetailsState.shipment.pod.enteredTime : ''}` : ''}
                                    labelFixWidth
                                  />
                                  <LabeledValue
                                    label="By:"
                                    value={shipmentDetailsState.shipment.pod.signature || ''}
                                    labelFixWidth
                                  />
                                </div>
                              )}
                            </>
                          )}
                          {!shipmentDetailsState?.shipment?.pod && <BlockPlaceholder />}
                        </div>
                      </InfoBlock>
                    </InfoGrid>
                  )}
                  <InvoiceDetailsInfo {...shipmentDetailsState?.shipment} />
                </>
              )}
              {shipmentDetailsState.shipment?.fullAccess && (shipmentDetailsState.shipment?.status?.title !== "BOOKED") && (
                <InvoiceDetailsTable
                  items={shipmentDetailsState.shipment?.items}
                  invoiceAmount={shipmentDetailsState.shipment?.freightCharges}
                />
              )}

              <ContentItem className="xgs-shipment-details__assets">
                <div className={`xgs-shipment-details__tabs${!(UserUtils.isXGSUser(userState.profile) || UserUtils.isXGSAdministrator(userState.profile)) && !shipmentDetailsState.shipment?.claims ? " xgs-shipment-details__tabs--single" : ""}`}>
                  <Tabs>
                    <TabList>
                      <Tab>Invoice</Tab>
                      {(UserUtils.isXGSUser(userState.profile) || UserUtils.isXGSAdministrator(userState.profile)) && invoiceNumber && (
                        <Tab>Images</Tab>
                      )}
                      {shipmentDetailsState.shipment?.claims && (
                        <Tab>Claims</Tab>
                      )}
                    </TabList>
                    <TabPanel>
                      {invoiceNumber && <InvoiceDetailsDocuments invoiceNumber={invoiceNumber} />}
                    </TabPanel>
                    {(UserUtils.isXGSUser(userState.profile) || UserUtils.isXGSAdministrator(userState.profile)) && invoiceNumber && (
                      <TabPanel>
                        <InvoiceDetailsRecords invoiceNumber={invoiceNumber} />
                      </TabPanel>
                    )}
                    {shipmentDetailsState.shipment?.claims && (
                      <TabPanel>
                        <ShipmentClaimsDocuments invoiceNumber={invoiceNumber} />
                      </TabPanel>
                    )}
                  </Tabs>
                </div>
              </ContentItem>

              {shipmentDetailsState.shipment?.fullAccess && (UserUtils.isXGSUser(userState.profile) || UserUtils.isXGSAdministrator(userState.profile)) && (
                <div className="xgs-shipment-details__tabs">
                  <Tabs>
                    <TabList>
                      <Tab>Dock Log</Tab>
                      <Tab>Comments</Tab>
                      <Tab>Status History</Tab>
                      <Tab>Exceptions</Tab>
                    </TabList>
                    <TabPanel>
                      <ShipmentDockLog invoiceNumber={invoiceNumber} />
                    </TabPanel>
                    <TabPanel>
                      {shipmentDetailsState.shipment?.notes && (
                        <>
                          {shipmentDetailsState.shipment?.notes?.map((note, i) => (
                            <ShipmentComment comment={note} key={`shipment-note-${i}`} />
                          ))}
                        </>
                      )}
                      {!shipmentDetailsState.shipment?.notes && (
                        <div className="xgs-shipment-details__tabs__no-data">There are no comments</div>
                      )}
                    </TabPanel>
                    <TabPanel>
                      <ShipmentStatusHistory invoiceNumber={invoiceNumber} />
                    </TabPanel>
                    <TabPanel>
                      <ShipmentExceptions probillNumber={invoiceNumber}/>
                    </TabPanel>
                  </Tabs>
                </div>
              )}
              {shipmentDetailsState.shipment?.fullAccess && shipmentDetailsState.shipment?.notes && !(UserUtils.isXGSUser(userState.profile) || UserUtils.isXGSAdministrator(userState.profile)) && (
                <ContentItem>
                  <ContentItemTitle>Comments</ContentItemTitle>
                  {shipmentDetailsState.shipment?.notes?.map((note, i) => (
                    <ShipmentComment comment={note} key={`shipment-note-${i}`} />
                  ))}
                </ContentItem>
              )}
            </div>
          )}
        </FitToWindowContainer>
      </ContentContainer>
      <InvoicePayment />
      {UserUtils.isEmployee(userState.profile) && <CreateException probillNumber={invoiceNumber}/>}
    </>
  );
};

export default ShipmentDetails;

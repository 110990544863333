import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import mixpanel from "mixpanel-browser";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import XGSIcon from "../../ui-components/icon/xgsIcon";
import XGSIcons from "../../ui-components/icon/xgsIcons";
import {
  getUserFeatureBanner,
  featureBannerSelector
} from "../../slices/feature-banner/featureBannerSlice";
import { cn } from "../../services/common/className";
import infoIcon from "../../images/info_blue.svg";
import closeIcon from "../../images/close_gray.svg";
import "./featureBanner.scss";

interface FeatureBannerProps {
  employee?: boolean;
};

const FeatureBanner: React.FC<FeatureBannerProps> = (props) => {
  const dispatch = useDispatch();
  const featureBannerState = useSelector(featureBannerSelector);
  const [showBanner, setShowBanner] = useState<boolean>(false);

  const goUrl = () => {
    if (!featureBannerState.banner?.url) return;
    mixpanel.track(`Top banner was clicked${props.employee ? " by employee" : " by customer"}`);
    window.open(featureBannerState.banner.url, "_blank");
  };

  const closeBanner = () => {
    if (!featureBannerState.banner?.date) return;
    setShowBanner(false);
    localStorage.setItem("xgs-feature-banner", featureBannerState.banner.date);
  };

  useEffect(() => {
    dispatch(getUserFeatureBanner());
  }, [dispatch]);

  useEffect(() => {
    if (!featureBannerState.banner) return;
    const date = localStorage.getItem("xgs-feature-banner");
    if (!date || date !== featureBannerState.banner.date) setShowBanner(true);
  }, [featureBannerState.banner]);

  return (
    <>
      {featureBannerState.banner && featureBannerState.banner?.message && featureBannerState.banner?.url && showBanner && (
        <div className={`xgs-animation__shaking ${cn("xgs-feature-banner")({employee: props.employee})}`}>
          {props.employee && <img src={infoIcon} className="xgs-feature-banner__info-icon" alt="info" />
          }
          <div className="xgs-feature-banner__text">{featureBannerState.banner.message}</div>
          <Button
            theme={props.employee ? ButtonThemes.blue : ButtonThemes.white}
            type="button"
            className="xgs-feature-banner__button"
            onClick={goUrl}
          >
            Read More
          </Button>
          {props.employee ? (
            <img
              src={closeIcon}
              className="xgs-feature-banner__close"
              onClick={closeBanner}
              alt="close"
            />
          ) : (
            <XGSIcon
              icon={XGSIcons.faTimes}
              className="xgs-feature-banner__close"
              onClick={closeBanner}
            />
          )}
        </div>
      )}
    </>
  );
};

export default FeatureBanner;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../ui-components/table/table";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import LabeledTextInput from "../../../ui-components/molecules/labeled-inputs/labeled-text-input/labeledTextInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import UsersState from "../../../slices/customers/users/TeamUsersState";
import {
  getUsers,
  resetErrors,
  usersSelector
} from "../../../slices/customers/users/teamUsersSlice";
import {
  decreaseTeamUsersCount,
  increaseTeamUsersCount
} from "../../../slices/customers/teams/teamsSlice";
import { customerRoles } from "../../../app/data/common/customerRoles";
import UserProfile from "../../../app/data/user/UserProfile";
import InviteUser from "../../invite-user/inviteUser";
import UserDetails from "../user-details/userDetails";
import { userStatuses } from "../constants";
import "./teams.scss";
import { agentRoles } from "../../../app/data/common/agentRoles";

export interface TeamUsersProps {
  id: string | undefined;
  agent?: boolean;
}

const TeamUsers: React.FC<TeamUsersProps> = (props) => {
  const dispatch = useDispatch();
  const usersState: UsersState = useSelector(usersSelector);
  const [userId, setUserId] = useState<string | undefined>();
  const [showInviteUser, setShowInviteUser] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string | undefined>("");

  const columns = [
    {
      width: 220,
      minWidth: 140,
      Header: "Name",
      accessor: "name",
      Cell: (cellProps: any) => (
        <>
          {cellProps.row.original.firstName} {cellProps.row.original.lastName}
        </>
      )
    },
    {
      width: 250,
      minWidth: 250,
      Header: "Email",
      accessor: "email",
      Cell: (cellProps: any) => (
        <>
          {cellProps.value}
          {cellProps.row.original.pendingEmail && (cellProps.value !== cellProps.row.original.pendingEmail) && (
            <>
              <br />
              <span className="xgs-teams__pending-email">({
                cellProps.row.original.pendingEmail.length > 25 ? cellProps.row.original.pendingEmail.substring(0, 25) + "..." : cellProps.row.original.pendingEmail
              })</span>
            </>
          )}
        </>
      )
    },
    {
      width: 110,
      minWidth: 110,
      Header: "Role",
      accessor: "role",
      Cell: (cellProps: any) => (
        <>
          {([...customerRoles, ...agentRoles]).find(role => role.value === cellProps.value)?.label}
        </>
      )
    },
    {
      Header: (
        <div className="text-center">
          Status
        </div>
      ),
      accessor: "status",
      width: 64,
      minWidth: 64,
      Cell: (cellProps: any) => (
        <div
          className="xgs-company__users__details__status__circle"
          style={{
            backgroundColor: userStatuses.find(status => status.value === cellProps.value)?.color,
            marginLeft: "auto",
            marginRight: "auto"
          }}
          title={cellProps.value.toLowerCase()}>
        </div>
      )
    }
  ];

  const onListInfiniteScroll = () => {
    if (!props.id) return;
    !usersState.usersFetchedAll && dispatch(getUsers(props.id, false, searchString, usersState.usersRequest?.lastIds));
  };

  const onUserClick = (row: UserProfile) => {
    setUserId(row.id);
  };

  const onSearchStart = () => {
    if (!props.id) return;
    dispatch(getUsers(props.id, false, searchString));
    setUserId(undefined);
  };

  const onSearchClear = () => {
    if (!props.id) return;
    setSearchString("");
    dispatch(getUsers(props.id, false, undefined));
    setUserId(undefined);
  };

  useEffect(() => {
    if (!props.id) return;
    dispatch(resetErrors());
    dispatch(getUsers(props.id, false, undefined));
    setUserId(undefined);
    setSearchString("");
  }, [dispatch, props.id]);

  return (
    <div>
      <div
        className="xgs-teams__details__add-button blue-link"
        onClick={() => {
          setShowInviteUser(true);
        }}
      >
        <XGSIcon
          icon={XGSIcons.faUserPlus}
          size="sm"
          className="xgs-teams__details__add-button__icon"
        />
        Invite User
      </div>
      <InviteUser        
        show={showInviteUser}
        agent={props.agent}
        onInviteSent={() => {
          if (!props.id) return;
          dispatch(getUsers(props.id, false, undefined));
          dispatch(increaseTeamUsersCount(props.id));
        }}
        onClose={() => {
          setShowInviteUser(false);
        }}
        teamId={props.id}
      />
      <div className="xgs-list__controls">
        <div className="xgs-list__controls__search">
          <LabeledTextInput
            labelMode={LabelModes.column}
            className="xgs-list__controls__search__input"
            value={searchString}
            onChange={(e) => setSearchString(e.currentTarget.value)}
            onKeyDown={(e) => (e.key === "Enter") && !(usersState.fetch_was_started && usersState.requestCreator === "GET_USERS") && onSearchStart()}
            type="text"
            placeholder="First, last name, email"
          />
          <Button
            className="xgs-list__controls__button"
            theme={ButtonThemes.blue}
            disabled={usersState.fetch_was_started && usersState.requestCreator === "GET_USERS"}
            onClick={onSearchStart}>
            Search
          </Button>
          <Button
            className="xgs-list__controls__button"
            theme={ButtonThemes.gray}
            onClick={onSearchClear}>
            Clear
          </Button>
        </div>
      </div>    
      {usersState.fetch_was_failed && (
        <XGSErrorMessage>{usersState.fetch_fail_reason}</XGSErrorMessage>
      )}
      {!usersState.fetch_was_failed && (
        <div className="xgs-teams__details__table-wrapper">
          <div className="xgs-teams__details__table">
            <Table
              autoHeight={true}
              isLoading={usersState.fetch_was_started}
              columns={columns}
              data={usersState.users}
              onRowClicked={onUserClick}
              cursorPointer={true}
              rowHeight={64}
              minTableHeight={420}
              noResultsText="There are no users"
              infiniteScroll={true}
              infiniteScrollLoading={usersState.fetchPortion_was_started}
              infiniteScrollHasNext={!usersState.usersFetchedAll}
              onInfiniteScroll={onListInfiniteScroll}
              responsive={true}
            />
            <UserDetails
              id={userId}
              show={!!userId}
              onClose={() => {
                setUserId(undefined);
              }}
              onDelete={() => {
                if (!props.id) return;
                dispatch(getUsers(props.id, false, undefined));
                dispatch(decreaseTeamUsersCount(props.id));
              }}
            />            
          </div>
        </div>
      )}
    </div>
  );
};

export default TeamUsers;

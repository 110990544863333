import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form, Formik, FormikProps } from "formik";
import { toast } from "react-toastify";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import XGSFormTextarea from "../../../ui-components/form/textarea/xgsFormTextarea";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import {
  FeatureBannerFormModel,
  FeatureBannerSchema
} from "../../../app/data/feature-banner/models";
import { UserUtils } from "../../../app/data/user/userUtils";
import { Routes } from "../../../app/route/RoutesConfig";
import {
  clearFeatureBanner,
  getFeatureBanners,
  saveFeatureBanner,
  featureBannerSelector
} from "../../../slices/feature-banner/featureBannerSlice";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import "../featureBanner.scss";

let initialValues: FeatureBannerFormModel = {
  message: "",
  url: ""
};

interface FeatureBannerFormProps {
  type: "CUSTOMER" | "EMPLOYEE";
};

const FeatureBannerForm: React.FC<FeatureBannerFormProps> = ({ type, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const featureBannerFormRef = useRef<any>(null);
  const featureBannerState = useSelector(featureBannerSelector);
  const userState: UserState = useSelector(userSelector);
  const [featureBanner, setFeatureBanner] = useState<any>(undefined);

  const onSubmit = (data: FeatureBannerFormModel) => {
    if (!data.recipientType) data.recipientType = type;
    dispatch(saveFeatureBanner(data, () => {
      toast.info("The banner was updated!", { autoClose: 3000 });
    }));
  };

  const clearForm = () => {
    dispatch(clearFeatureBanner(type, () => {
      toast.info("The banner was cleared!", { autoClose: 3000 });
      featureBannerFormRef.current.resetForm();
    }));
  };

  useEffect(() => {
    if (UserUtils.isXGSAdministrator(userState.profile)) {
      dispatch(getFeatureBanners((banners: any[]) => {
        setFeatureBanner(banners.find(banner => banner.recipientType === type));
      }));
    } else {
      history.push(Routes.home);
    }
  }, [dispatch, history, userState.profile, type]);

  useEffect(() => {
    if (!featureBanner) return;
    featureBannerFormRef.current.setFieldValue("message", featureBanner.message || "");
    featureBannerFormRef.current.setFieldValue("url", featureBanner.url || "");
  }, [featureBanner]);

  return (
    <div className="xgs-feature-banner__management">
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={FeatureBannerSchema}
        innerRef={featureBannerFormRef}
      >
        {(props: FormikProps<FeatureBannerFormModel>) => (
          <Form className="form-items xgs-form__container">
            <XGSFormTextarea
              name="message"
              label="Message:"
              required={false}
              className="xgs-feature-banner__management__message"
              counter={150}
              placeholder="Plain text"
            />
            <XGSFormInput
              type="text"
              name="url"
              label="URL:"
              required={false}
              labelMode={LabelModes.column}
              className=""
              placeholder="https://somedomain.com/example"
            />
            <div className="xgs-form__field__notes" style={{ marginTop: 24 }}>
              <strong>Note:</strong> the banner will be displayed to {type === "CUSTOMER" ? "customers" : "employees"} if both &quot;Message&quot; and &quot;URL&quot; fields are filled.
            </div>
            {featureBannerState.requestFailed && (
              <div style={{ marginTop: 24 }}>
                <XGSErrorMessage>{featureBannerState.requestError}</XGSErrorMessage>
              </div>
            )}
            <div className="xgs-feature-banner__management__buttons">
              <Button
                theme={ButtonThemes.blue}
                className=""
                disabled={!props.isValid || !props.dirty}
                spinner={featureBannerState.requestStarted && featureBannerState.requestCreator === "SAVE"}
              >
                Save
              </Button>
              <Button
                theme={ButtonThemes.gray}
                className=""
                onClick={clearForm}
                disabled={(featureBannerState.requestStarted && featureBannerState.requestCreator === "GET") || !(props.values.message || props.values.url)}
                spinner={featureBannerState.requestStarted && featureBannerState.requestCreator === "CLEAR"}
                type="button"
              >
                Clear
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FeatureBannerForm;

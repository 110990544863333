import React from "react";

import {ReactComponent as InfoIcon} from "../../../images/info_dark.svg";
import { cn } from "../../../services/common/className";

import "./importantMessage.scss";

export enum ImportantMessageTheme {
  BLUE = "blue",
};

export interface ImportantMessageProps {
  children: any;
  theme: ImportantMessageTheme;
  mix: string;
}

const ImportantMessage: React.FC<ImportantMessageProps> = (props) => {
  return (
    <div className={cn("xgs-important-message")({theme: props.theme}, props.mix)}>
      <InfoIcon        
        className="xgs-important-message__icon"
      />
      <div className="xgs-important-message__text">
        {props.children}
      </div>
    </div>
  );
}

export default ImportantMessage;
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Form, Formik, FormikProps, Field } from "formik";
import { InviteSendModel, InviteSendSchema } from "../../app/data/invite-management/models";
import XGSPopup from "../../ui-components/xgs-popup/xgsPopup";
import XGSFormInput from "../../ui-components/form/input/xgsFormInput";
import { LabelModes } from "../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import InviteManagementState from "../../slices/invite-management/InviteManagementState";
import {
  inviteManagementSelector,
  resetErrors,
  sendInvite
} from "../../slices/invite-management/inviteManagementSlice";
import { ERROR_MESSAGES } from "../../app/data/common/errorMessages";

import "./inviteUser.scss";

export interface InviteUserProps {  
  agent?: boolean;
  show: boolean;
  teamId?: string;
  onInviteSent?: () => void;
  onClose: () => void;
}

let initialValues: InviteSendModel = {
  email: "",
  firstName: "",
  lastName: "",
  administrator: false
};

const InviteUser: React.FC<InviteUserProps> = ({agent, ...props}) => {
  const inviteManagementState: InviteManagementState = useSelector(inviteManagementSelector);
  const dispatch = useDispatch();

  const onSubmitInvite = (data: InviteSendModel) => {
    if (props.teamId) {
      data.teamId = props.teamId;
    }
    dispatch(sendInvite(data, () => {
      toast.info("Invitation sent!");
      props.onInviteSent && props.onInviteSent();
      props.onClose();
    }));
  };

  useEffect(() => {
    dispatch(resetErrors());
    return () => {
      dispatch(resetErrors());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!inviteManagementState.request_was_failed) return;
    toast.error(inviteManagementState.request_fail_reason || ERROR_MESSAGES.COMMON);
  }, [inviteManagementState.request_was_failed, inviteManagementState.request_fail_reason]);

  return (
    <XGSPopup
      title=""
      opened={props.show}
      onClose={props.onClose}
      className="xgs-invite-user__popup"
    >
      <div>
        <div className="xgs-invite-user__popup__header">Invite user to {props.teamId ? "the" : "your"} team</div>
        <Formik
          onSubmit={onSubmitInvite}
          initialValues={initialValues}
          validationSchema={InviteSendSchema}
        >
          {(props: FormikProps<InviteSendModel>) => (
            <Form>
              <div className="xgs-invite-user__popup__field">
                <XGSFormInput
                  type="text"
                  name="email"
                  label="Email:"
                  required={true}
                  requiredAsteriskDisabled={true}
                  labelMode={LabelModes.column}
                />
              </div>
              <div className="xgs-invite-user__popup__field">
                <XGSFormInput
                  type="text"
                  name="firstName"
                  label="First Name:"
                  required={true}
                  requiredAsteriskDisabled={true}
                  labelMode={LabelModes.column}
                />
              </div>
              <div className="xgs-invite-user__popup__field">
                <XGSFormInput
                  type="text"
                  name="lastName"
                  label="Last Name:"
                  required={true}
                  requiredAsteriskDisabled={true}
                  labelMode={LabelModes.column}
                />
              </div>
              {!agent && <div className="xgs-invite-user__popup__checkbox">
                <label>
                  <Field
                    type="checkbox"
                    name="administrator"
                  />
                  <div className="xgs-invite-user__popup__checkbox__label">Make Administrator</div>
                </label>
              </div>}
              <Button
                theme={ButtonThemes.blue}
                type="submit"
                className="xgs-invite-user__popup__button"
                disabled={!props.isValid || !props.dirty}
                spinner={inviteManagementState.request_was_started}
              >
                Send&nbsp;invitation
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </XGSPopup>
  );
};

export default InviteUser;

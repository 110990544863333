import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import debounce from "lodash/debounce";
import Modal from "react-modal";
import Loading from "../../../../ui-components/loading/loading";
import XGSIcons from "../../../../ui-components/icon/xgsIcons";
import XGSIcon from "../../../../ui-components/icon/xgsIcon";
import XGSFormInput from "../../../../ui-components/form/input/xgsFormInput";
import XGSFormTextarea from "../../../../ui-components/form/textarea/xgsFormTextarea";
import { XGSSelectOption } from "../../../../ui-components/xgs-select/xgsSelect";
import { LabelModes } from "../../../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledSelectInput from "../../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import XGSFormDate from "../../../../ui-components/form/date/xgsFormDate";
import XGSFormPhoneInput from "../../../../ui-components/form/phoneInput/xgsFormPhoneInput";
import {
  PickupShipperModel,
  PickupShipperSchema
} from "../../../../app/data/pickup/models";
import { StepProps } from "../../../../app/data/common/models";
import {
  pickupSelector,
  checkPickup,
  clearShippers,
  // clearItems,
  getShippers,
  searchCompany,
  setShipper,
  setTeamName,
  setShippersSearch,
  addShipper
} from "../../../../slices/pickup/pickupSlice";
import {
  commonSelector,
  getFreightClasses,
  getPickupDays
} from "../../../../slices/common/commonSlice";
import { modalStyle } from "../../../../app/data/common/modalStyle";
import { fromTimeOptions, toTimeOptions } from "../../../../services/common/time";
import { firstServiceCenterWorkDay } from "../../../../services/common/date";
import ShipperCreate from "../../../../ui-components/shipper-create/shipperСreate";
import XGSPopup, { PopupTheme } from "../../../../ui-components/xgs-popup/xgsPopup";
import CustomersService from "../../../../app/data/customers/customersService";
import { SubAccountMinimal } from "../../../../app/data/account/SubAccount";
import { shipperSelector } from "../../../../slices/shipper/shipperSlice";
import { makeId } from "../../../../hooks/utils";
import Warning from "../../../../ui-components/molecules/warning/warning";
import ImportantMessage, { ImportantMessageTheme } from "../../../../ui-components/molecules/important-message/importantMessage";

let initialValues: PickupShipperModel = {
  teamId: "",
  shipper: {
    id: null,
    accountNumber: null,
    name: "",
    address: {
      address1: "",
      city: "",
      postalCode: "",
      state: ""
    },
    contacts: {
      firstName: "",
      lastName: "",
      mobilePhone: "",
      allowSms: false
    }
  },
  bolNumber: "",
  poNumber: "",
  additionalReference: "",
  specialInstructions: "",
  pickupDate: "",
  pickupReadyTime: undefined,
  pickupCloseTime: undefined
}

const customerService = CustomersService.getInstance();

const PickupShipperStep: React.FC<StepProps> = (props) => {
  const { next } = props;
  const pickupState = useSelector(pickupSelector);
  const commonState = useSelector(commonSelector);
  const shipperState = useSelector(shipperSelector);
  const dispatch = useDispatch();
  const shipperFormRef = useRef<any>(null);
  const [team, setTeam] = useState<XGSSelectOption | null | undefined>();
  const [shipperFormValue, setShipperFormValue] = useState<XGSSelectOption | null>();
  const [pickupAvailable, setPickupAvailable] = useState<boolean>(true);
  const [pickupDateFormValue, setPickupDateFormValue] = useState<string | undefined>();
  const [pickupReadyTimeFormValue, setPickupReadyTimeFormValue] = useState<XGSSelectOption | null>();
  const [pickupCloseTimeFormValue, setPickupCloseTimeFormValue] = useState<XGSSelectOption | null>();  
  const [mobilePhoneValue, setMobilePhoneValue] = useState<string>("");
  const [allowSms, setAllowSms] = useState<boolean>(false);
  const [hazMat, setHazMat] = useState<boolean>(false);
  const [shipperCreated, setShipperCreated] = useState<boolean>(false);
  const [shipperQuery, setShipperQuery] = useState("");

  const shippers = shipperQuery ? pickupState.shippersSearch : pickupState.shippers;

  let companySearch = (value: string) => {
    value?.length > 1 && dispatch(searchCompany(value));
  }
  companySearch = debounce(companySearch, 300);

  const onChangeTeam = (_team: XGSSelectOption | null | undefined) => {
    setTeam(_team);
    setShipperFormValue(null);
    const selectedTeamId = _team ? _team.value : null;
    dispatch(clearShippers());
    selectedTeamId && dispatch(getShippers(selectedTeamId));
    _team && dispatch(setTeamName(_team.label));
    shipperFormRef.current?.setFieldValue("teamId", selectedTeamId);
    shipperFormRef.current?.setFieldValue("shipper.id", undefined);
    shipperFormRef.current?.setFieldValue("pickupDate", "");
    setPickupDateFormValue(undefined);
  };

  const shipperOption = (shipper: SubAccountMinimal) => ({    
      label: `${shipper.name} (${shipper.accountNumber})`,
      subtitle: `
        ${(shipper.address?.line1 && (shipper.address?.line1 !== "-")) ? shipper.address?.line1.toLowerCase().capitalize() + ", " : ""}
        ${(shipper.address?.city && (shipper.address?.city !== "-")) ? shipper.address?.city.toLowerCase().capitalize() : ""}${
          (shipper.address?.city && (shipper.address?.city !== "-") && shipper.address?.state && (shipper.address?.state !== "-")) ? ", " : ""}${
            (shipper.address?.state && (shipper.address?.state !== "-")) ? shipper.address?.state : ""}
      `,
      value: `${shipper.id}`    
  });

  const shipperOptions = useMemo(() => {
    let options: XGSSelectOption[] = [];
    if (pickupState.shippers && pickupState.shippers.length > 0) {
      for (let i = 0; i < pickupState.shippers.length; i++) {
        if (!pickupState.shippers[i].isNew && pickupState.shippers[i].id) {
          options.push(shipperOption(pickupState.shippers[i]));
        }        
      }
    }
    if (options.length > 0) {
      options = uniqBy(options, "value");
      options = sortBy(options, ["label"]);
    }
    return options;
  }, [pickupState.shippers]);

  const groupOptions = (accountOptions: XGSSelectOption[], globalOptions?: XGSSelectOption[]) => ([
    {
      label: "Results related to requester:",
      options: accountOptions,
    },
    {
      label: "All results:",
      options: globalOptions || [],
    }
  ]);

  const groupedShipperOptions = useMemo(() => groupOptions(shipperOptions), [shipperOptions]);

  const setSelectedShipper = (shipper?: SubAccountMinimal | null) => {    
    shipperFormRef.current?.setFieldValue("shipper.id", shipper?.id || undefined);
    shipperFormRef.current?.setFieldValue("shipper.accountNumber", shipper?.accountNumber || undefined);

    // TODO check
    // if (shipperFormValue?.value !== shipper.id) {
    //   dispatch(clearItems());
    // }  
    
    shipperFormRef.current?.setFieldValue("shipper.name", shipper?.name || undefined);
    shipperFormRef.current?.setFieldValue("shipper.address.address1",  shipper?.address?.line1 || undefined);
    shipperFormRef.current?.setFieldValue("shipper.address.city",  shipper?.address?.city || undefined);
    shipperFormRef.current?.setFieldValue("shipper.address.postalCode",  shipper?.address?.zip || undefined);
    shipperFormRef.current?.setFieldValue("shipper.address.state",  shipper?.address?.state || undefined);
    shipperFormRef.current?.setFieldValue("pickupDate", "");
    setPickupDateFormValue(undefined);
  };

  const onChangeTeamAccount = (account: XGSSelectOption | null | undefined) => {
    setShipperFormValue(account);   

    const shipper = shippers && shippers.find(obj => obj.id === account?.value);

    if (shipper?.address?.zip) {
      checkPickupAvailability(shipper?.address.zip);
      dispatch(getPickupDays(shipper?.address.zip));      
    }

    setSelectedShipper(shipper);   
  };
  
  const onAddShipper = () => {
    const shipper: SubAccountMinimal = { 
      ...shipperState.shipper,
      address: {
        line1: shipperState.shipper.address.address1,
        line2: "",
        city: shipperState.shipper.address.city,
        state: shipperState.shipper.address.state,
        zip: shipperState.shipper.address.postalCode,
        country: "",
      },
      id: makeId(5),
      accountNumber: 69,
      isNew: true,
     };

    dispatch(addShipper(shipper));

    const option = shipperOption(shipper);
    setShipperFormValue(option);

    if (shipper?.address?.zip) {
      dispatch(getPickupDays(shipper?.address?.zip));
    }

    setSelectedShipper(shipper);
    setShipperCreated(false);
  }

  const onClickNext = (data: PickupShipperModel) => {
    if (data.shipper?.address.postalCode) {
      dispatch(getFreightClasses(data.shipper?.address.postalCode));
    }
       
    dispatch(setShipper(data));
    next && next();
  };

  const checkPickupAvailability = (zip: string) => {    
    zip && dispatch(checkPickup(zip, () => setPickupAvailable(true), () => setPickupAvailable(false)));
  };

  const getSearchOptions = (shippers: SubAccountMinimal[]) => groupOptions(
    shippers
      .filter(shipper => shipper.paypointNumber === pickupState.paypointNumber)
      .map(shipperOption),
    shippers
      .filter(shipper => shipper.paypointNumber !== pickupState.paypointNumber)
      .map(shipperOption),
  );

  // eslint-disable-next-line
  const searchShipper = React.useCallback(
    debounce((query, callback) => {
      if (!pickupState.paypointNumber) {        
        return;
      }

      customerService.getShippersGlobalSearch(query, pickupState.paypointNumber)
      .then(options => {
        const shippers = options.map(option => ({ ...option, id: makeId(5) }));
        dispatch(setShippersSearch(shippers));
        callback(getSearchOptions(shippers)
      )})
    }, 300),
    [pickupState.paypointNumber]
  );

  useEffect(() => {
    if (pickupState.teamId) {
      initialValues.teamId = pickupState.teamId;
      setTeam({
        value: pickupState.teamId,
        label: pickupState.teamName
      });
    }
    if (pickupState.shipper.id) {
      initialValues.shipper.id = pickupState.shipper.id;
      let shipperObj = pickupState.shippers?.find(obj => obj.id === pickupState.shipper.id) 
      || pickupState.shippersSearch?.find(obj => obj.id === pickupState.shipper.id);
      shipperObj && setShipperFormValue(shipperOption(shipperObj));
    }
    initialValues.shipper.accountNumber = pickupState.shipper.accountNumber;
    initialValues.shipper.name = pickupState.shipper.name;
    initialValues.shipper.address.address1 = pickupState.shipper.address.address1;
    initialValues.shipper.address.city = pickupState.shipper.address.city;
    initialValues.shipper.address.postalCode = pickupState.shipper.address.postalCode;
    initialValues.shipper.address.state = pickupState.shipper.address.state;
    initialValues.bolNumber = pickupState.bolNumber;
    initialValues.poNumber = pickupState.poNumber;
    initialValues.additionalReference = pickupState.additionalReference;
    initialValues.specialInstructions = pickupState.specialInstructions;
    initialValues.pickupDate = pickupState.pickupDate;
    initialValues.pickupReadyTime = pickupState.pickupReadyTime;
    initialValues.pickupCloseTime = pickupState.pickupCloseTime;
    shipperFormRef.current?.setFieldValue("pickupDate", pickupState.pickupDate);
    setPickupDateFormValue(pickupState.pickupDate);
    if (pickupState.pickupReadyTime) {
      setPickupReadyTimeFormValue({
        value: pickupState.pickupReadyTime,
        label: pickupState.pickupReadyTime
      });
    }
    if (pickupState.pickupCloseTime) {
      setPickupCloseTimeFormValue({
        value: pickupState.pickupCloseTime,
        label: pickupState.pickupCloseTime
      });
    }
    initialValues.shipper.contacts.firstName = pickupState.shipper.contacts.firstName;
    initialValues.shipper.contacts.lastName = pickupState.shipper.contacts.lastName;
    initialValues.shipper.contacts.mobilePhone = pickupState.shipper.contacts.mobilePhone;
    pickupState.shipper.contacts?.mobilePhone && setMobilePhoneValue(pickupState.shipper.contacts.mobilePhone);
    initialValues.shipper.contacts.allowSms = pickupState.shipper.contacts.allowSms;
    setAllowSms(pickupState.shipper.contacts.allowSms || false);
    initialValues.shipper.contacts.email = pickupState.shipper.contacts.email;
    setTimeout(() => {
      shipperFormRef.current?.validateForm();
    }, 50);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // preselect shipper if it is alone in dropdown
    if (pickupState.shippers === null || pickupState.shipper) return;
    if (shipperOptions.length === 1) {
      onChangeTeamAccount(shipperOptions[0]);
    }
    // eslint-disable-next-line
  }, [pickupState.shippers, pickupState.shipper, shipperOptions]);

  useEffect(() => {
    if (!pickupCloseTimeFormValue) return;
    const options = toTimeOptions(pickupReadyTimeFormValue?.value);
    const toIndex = options.findIndex((obj: XGSSelectOption) => obj.value === pickupCloseTimeFormValue?.value);
    if (toIndex === -1) {
      // if current "To" time is out of allowed time options
      setPickupCloseTimeFormValue(options[0]);
      shipperFormRef.current?.setFieldValue("pickupCloseTime", options[0].value);
    }
  }, [pickupCloseTimeFormValue, pickupReadyTimeFormValue]);

  return (
    <div className="xgs-pickup__shipper">
      <Formik
        onSubmit={onClickNext}
        initialValues={initialValues}
        validationSchema={PickupShipperSchema}
        innerRef={shipperFormRef}
        enableReinitialize
      >
        {(props: FormikProps<PickupShipperModel>) => (
          <Form>
            <div className="xgs-pickup__step-header">
              Shipper Information
              {pickupState.requestStarted && pickupState.requestCreator === "CHECK_PICKUP" && <Loading isLoading={true} className="xgs-pickup__step-header__loader" />}
            </div>
            <LabeledSelectInput
              isDisabled={false}
              isLoading={pickupState.requestStarted && pickupState.requestCreator === "SEARCH_COMPANY"}
              label="Requester (Company):"
              labelMode={LabelModes.column}
              onInputChange={companySearch}
              onValueChange={onChangeTeam}
              openMenuOnClick={pickupState.companies?.length > 0}
              options={pickupState.companies}
              required={true}
              requiredAsteriskDisabled={false}
              placeholder="Enter company name..."
              value={team}
              isClearable
              className="xgs-pickup__field"
            />
            <LabeledSelectInput
              name="shipper"
              label="Pickup location (Shipper):"
              labelMode={LabelModes.column}
              value={shipperFormValue}
              onValueChange={onChangeTeamAccount}
              onInputChange={setShipperQuery}
              options={groupedShipperOptions}
              async
              cacheOptions={false}
              loadOptions={searchShipper}
              required={true}
              requiredAsteriskDisabled={false}
              formik={true}
              className="xgs-pickup__field"
              isLoading={pickupState.requestStarted && pickupState.requestCreator === "GET_SHIPPERS"}
              disabled={!team}
              isClearable
              extendAction={pickupState.paypointNumber && shipperQuery ? () => setShipperCreated(true) : undefined}
              extendLabel="Add new shipper"
              placeholder="Start typing..."
            />            
            {shipperFormValue && (
              <div className="xgs-pickup__shipper__address-block">
                <div>Address: <span>{props.values.shipper.address.address1}</span></div>
                <div>City: <span>{props.values.shipper.address.city}</span></div>
                <div>State: <span>{props.values.shipper.address.state}</span></div>
                <div>Zip: <span>{props.values.shipper.address.postalCode}</span></div>
              </div>
            )}
            {shipperFormValue && !pickupAvailable && !(pickupState.requestStarted && pickupState.requestCreator === "CHECK_PICKUP") && (
              <Warning>
                The pickup request option isn't available for&nbsp;the&nbsp;current shipper!
              </Warning>
            )}
            {(pickupAvailable || shipperCreated) && (
              <>
                <div className="xgs-pickup__field">
                  <div className="xgs-form__checkbox">
                    <label>
                      <input
                        type="checkbox"
                        name="hazMat"
                        onClick={() => setHazMat(!hazMat)}
                        checked={hazMat}
                      />
                      <div>
                        Will there be hazardous materials?
                      </div>
                    </label>
                  </div>
                </div>
                <div className="xgs-gray-area  xgs-pickup__field">
                  <ImportantMessage theme={ImportantMessageTheme.BLUE} mix="xgs-pickup__row">
                    Please enter the information if available
                  </ImportantMessage>
                  <div className="xgs-pickup__row">
                    <div className="xgs-pickup__row__item">
                      <XGSFormInput
                        type="text"
                        name="bolNumber"
                        label="BOL #:"
                        required={false}
                        requiredAsteriskDisabled={true}
                        labelMode={LabelModes.column}
                      />
                    </div>
                    <div className="xgs-pickup__row__item">
                      <XGSFormInput
                        type="text"
                        name="poNumber"
                        label="PO #:"
                        required={false}
                        requiredAsteriskDisabled={true}
                        labelMode={LabelModes.column}
                      />
                    </div>
                  </div>
                  <XGSFormInput
                    type="text"
                    name="additionalReference"
                    label="Additional Reference:"
                    required={false}
                    requiredAsteriskDisabled={true}
                    labelMode={LabelModes.column}
                    className="xgs-pickup__field"
                  />
                </div>
                <div className="xgs-pickup__row">
                  <div className="xgs-pickup__date">
                    <XGSFormDate
                      name="pickupDate"
                      label="Pickup Date:"
                      placeholder="Select date..."
                      onDateChange={(v) => {
                        props.setFieldValue("pickupDate", v || "");
                        setPickupDateFormValue(v);
                      }}
                      value={pickupDateFormValue}
                      onChange={() => null}
                      minDate={new Date(firstServiceCenterWorkDay(commonState.pickupDays))}
                      required={true}
                      labelMode={LabelModes.column}
                      disableWeekends={true}
                      disabledWeekDays={commonState.pickupDays}
                      disabled={!shipperFormValue || (commonState.requestStarted && (commonState.requestCreator === "GET_SERVICE_CENTER_DAYS"))}
                    />
                  </div>
                  <div className="xgs-pickup__time xgs-bol__return__availability">
                    <div className="xgs-bol__return__availability__label">Hours of Availability: <span>*</span></div>
                    <div className="xgs-bol__return__availability__controls">
                      <LabeledSelectInput
                        name="pickupReadyTime"
                        value={pickupReadyTimeFormValue}
                        label=""
                        isSearchable={false}
                        labelMode={LabelModes.column}
                        onValueChange={(v) => {
                          props.setFieldValue("pickupReadyTime", v?.value);
                          setPickupReadyTimeFormValue(v);
                        }}
                        options={fromTimeOptions()}
                        required={true}
                        requiredAsteriskDisabled={true}
                        formik={true}
                        placeholder="Ready..."
                        menuPlacement="top"
                      />
                      <div className="xgs-bol__return__availability__controls__delimiter">-</div>
                      <LabeledSelectInput
                        name="pickupCloseTime"
                        value={pickupCloseTimeFormValue}
                        label=""
                        isSearchable={false}
                        labelMode={LabelModes.column}
                        onValueChange={(v) => {
                          props.setFieldValue("pickupCloseTime", v?.value);
                          setPickupCloseTimeFormValue(v);
                        }}
                        options={toTimeOptions(pickupReadyTimeFormValue?.value)}
                        required={true}
                        requiredAsteriskDisabled={true}
                        formik={true}
                        placeholder="Close..."
                        menuPlacement="top"
                      />
                    </div>
                  </div>
                </div>
                <div className="xgs-pickup__textarea xgs-pickup__field">
                  <XGSFormTextarea
                    name="specialInstructions"
                    value={props.values.specialInstructions}
                    label="Special Instructions:"
                    required={false}
                    rows={4}
                    counter={150}
                  />
                </div>
                <div className="xgs-gray-area">
                  <div className="xgs-gray-area__header">
                    Shipper contacts
                  </div>
                  <div className="xgs-pickup__row">
                    <div className="xgs-pickup__row__item">
                      <XGSFormInput
                        type="text"
                        name="shipper.contacts.firstName"
                        label="First Name:"
                        requiredAsteriskDisabled
                        labelMode={LabelModes.column}
                      />
                    </div>
                    <div className="xgs-pickup__row__item">
                      <XGSFormInput
                        type="text"
                        name="shipper.contacts.lastName"
                        label="Last Name:"
                        requiredAsteriskDisabled
                        labelMode={LabelModes.column}
                      />
                    </div>
                  </div>
                  <div className="xgs-pickup__row xgs-pickup__mobile-phone-row">
                    <div className="xgs-pickup__row__item">
                      <XGSFormPhoneInput
                        name="shipper.contacts.mobilePhone"
                        label="Mobile Phone:"
                        labelMode={LabelModes.column}
                        onValueChange={(value) => {
                          props.setFieldValue("shipper.contacts.mobilePhone", value);
                          setMobilePhoneValue(value);
                        }}
                        onBlur={props.handleBlur}
                        value={mobilePhoneValue}
                      />
                    </div>
                    <div className="xgs-pickup__row__item">
                      <div className="xgs-form__checkbox">
                        <label>
                          <input
                            type="checkbox"
                            name="shipper.contacts.allowSMS"
                            onClick={() => {
                              props.setFieldValue("shipper.contacts.allowSms", !allowSms);
                              setAllowSms(!allowSms);
                            }}
                            checked={allowSms}
                          />
                          <div>
                            Allow SMS
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="xgs-form__field__notes">
                    <strong>Note:</strong> by providing a telephone number and checking this checkbox you are consenting to be contacted by SMS text message.
                    Message &amp; data rates may apply. You can reply STOP to opt-out of further messaging.
                  </div>
                  <XGSFormInput
                    type="text"
                    name="shipper.contacts.email"
                    label="Email:"
                    labelMode={LabelModes.column}
                    required
                  />
                </div>
              </>
            )}
            <div className="xgs-pickup__buttons">
              <Button
                theme={ButtonThemes.blue}
                disabled={!props.isValid || !props.values.pickupDate}
                className="xgs-bol__nav-button"
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <Modal
        isOpen={hazMat}
        style={modalStyle}
      >
        <>
          <div className="xgs-modal__content">
            <div className="xgs-pickup__popup__warning">
              <XGSIcon
                icon={XGSIcons.faExclamationCircle}
                className="xgs-pickup__popup__warning__icon"
              />
              <div>
                Please call customer service - XGS&nbsp;is&nbsp;not&nbsp;an&nbsp;approved HazMat carrier.
              </div>
            </div>
          </div>
          <div className="xgs-modal__buttons">
            <Button
              type="button"
              theme={ButtonThemes.blue}
              className="xgs-modal__button"
              onClick={() => setHazMat(false)}
            >
              Close
            </Button>
          </div>
        </>
      </Modal>

      <XGSPopup
        opened={shipperCreated}
        onClose={() => {
          setPickupAvailable(true);
          setShipperCreated(false)
        }}
        className="shipper-create-popup"
        closeOnDocumentClick={false}
        theme={PopupTheme.NEW}
      >
        <ShipperCreate
          onCreate={onAddShipper}
          onZipChange={(zip) => {
            setPickupAvailable(true);

            if (zip.length === 5) {
              checkPickupAvailability(zip);
            }
          }}
          isLoading={pickupState.requestStarted && pickupState.requestCreator === "CHECK_PICKUP"}
          error={pickupAvailable
            ? ""
            : "The pickup request option isn't available for the current zip!"} 
            />
      </XGSPopup>
    </div>
  );
};

export default PickupShipperStep;
